import { onDomContentLoaded } from "./utils";

function toggleButton(event) {
  const passwordInput = event.currentTarget.parentNode.querySelector("input");
  const showPasswordButton = passwordInput.parentNode.querySelector(
    "button.show-password",
  );
  const hidePasswordButton = passwordInput.parentNode.querySelector(
    "button.hide-password",
  );
  if (passwordInput.type === "password") {
    passwordInput.type = "text";
    showPasswordButton.classList.add("hidden");
    hidePasswordButton.classList.remove("hidden");
    hidePasswordButton.focus();
  } else {
    passwordInput.type = "password";
    hidePasswordButton.classList.add("hidden");
    showPasswordButton.classList.remove("hidden");
    showPasswordButton.focus();
  }
}

function addButtonEvents() {
  const passwordInputs = document.querySelectorAll("input[type='password']");
  for (const passwordInput of passwordInputs) {
    for (const button of passwordInput.parentNode.querySelectorAll("button")) {
      button.addEventListener("click", showPassword.toggleButton);
      if (button.classList.contains("show-password")) {
        button.classList.remove("hidden");
      }
    }
  }
}

export const showPassword = {
  toggleButton,
  addButtonEvents,
};

onDomContentLoaded(showPassword.addButtonEvents);
